// import { isEmpty } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import API_ENDPOINTS from '../../api/api-endpoints';
import { get } from '../../api/api';

export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

export const fetchProductSuccess = (data) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: data,
});

/**
 * Формирование ссылки для запроса данных
 */
export const getQueryLink = ({ mode, pageId, id }) => {
  const productsPath = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PRODUCTS}`;
  switch (mode) {
  case 'all':
    return productsPath;
  case 'search':
    return `${productsPath}`;
  case 'filter':
    return `${productsPath}/page/${pageId}`;
  case 'related':
    return `${productsPath}/${id}/related`;
  default:
    return `${productsPath}`;
  }
};

let total = 0;

export const getProducts = async (props) => {
  const {
    pageParam = 1,
    queryKey,
  } = props;

  const {
    limit,
    mode,
    searchTerm,
    pageId,
    id
  } = queryKey[0];

  const offset = (pageParam - 1) * limit;

  const queryLink = getQueryLink({ mode, pageId, id });
  let queryString = '';

  if (mode === 'all') {
    queryString = `?offset=${offset}&limit=${limit}`;
  }
  // search
  if (mode === 'search') {
    queryString = `?offset=${offset}&limit=${limit}&conditionValue=${searchTerm}`;
  }
  // filter
  if (mode === 'filter') {
    queryString = `?offset=${offset}&limit=${limit}`;
  }
  const link = `${queryLink}${queryString}`;
  const result = await get(link);
  const data = result.data.items;
  total = result.data.total;
  console.log(data);
  return data;
};

export const useProductsQuery = (props) => useInfiniteQuery(
  [props],
  getProducts,
  {
    keepPreviousData: true,
    // eslint-disable-next-line no-unused-vars, max-len
    getNextPageParam: (lastPage, pages) => (pages.length < (total / props.limit) ? (pages.length + 1) : undefined)
  }
);
