import React from 'react';

import { useModalAction, useModalState } from './modalContext';
import ModalWrapper from './ModalWrapper';
import ProductPopup from '../../products/ProductPopup';

function ManagedModal() {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <ModalWrapper
      open={isOpen}
      onClose={closeModal}
    >
      {view === 'PRODUCT_DETAILS' && (
        <ProductPopup
          productSlug={data}
        />
      )}
    </ModalWrapper>
  );
}

export default ManagedModal;
