import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

function Accordion(props) {
  const {
    items,
  } = props;

  const [expanded, setExpanded] = useState(0);

  return (
    items?.map((item, index) => (
      <AccordionItem
        i={index}
        key={index}
        item={item}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    ))
  );
}

export default Accordion;
