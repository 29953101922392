import React from 'react';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide, Navigation } from '../ui/slider/Slider';
import { ArrowPrevIcon, ArrowNextIcon } from '../icons';

const offerSliderBreakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  580: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  1920: {
    slidesPerView: 4,
    spaceBetween: 24,
  },
};

function PromotionSlider(props) {
  const { page } = props;

  const { t } = useTranslation();

  const s = page.blocks.find((b) => b.name === 'Carousel');
  if (!s) {
    return;
  }
  const { images } = s;

  return (
    <div className="px-6 py-5 md:p-8 border-t border-border-200 bg-light">
      <div className="relative">
        <Swiper
          id="offer"
          loop
          breakpoints={offerSliderBreakpoints}
          modules={[Navigation]}
          navigation={{
            nextEl: '.next',
            prevEl: '.prev',
          }}
        >
          {images.map((image, i) => (
            <SwiperSlide key={i}>
              <img
                className="w-full h-auto responsive"
                src={image.downloadLink}
                alt="..."
                width="580"
                height="270"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="prev cursor-pointer absolute top-2/4 -left-4 md:-left-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-orange-400 hover:text-light hover:border-orange-400"
          role="button"
        >
          <span className="sr-only">{t('Previous')}</span>
          <ArrowPrevIcon width={18} height={18} />
        </div>
        <div
          className="next cursor-pointer absolute top-2/4 -right-4 md:-right-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-orange-400 hover:text-light hover:border-orange-400"
          role="button"
        >
          <span className="sr-only">{t('Next')}</span>
          <ArrowNextIcon width={18} height={18} />
        </div>
      </div>
    </div>
  );
}

export default PromotionSlider;
