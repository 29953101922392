/* eslint-disable default-param-last */
import {
  // FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCT_SUCCESS,
  // FETCH_PRODUCTS_SUCCESS
} from './action';

const initialState = {
  products: [],
  product: {},
  total: null,
  isLoading: false,
  isError: null,
  error: null,
  fetchNextPage: null,
  isFetching: false,
  isFetchingNextPage: false,
  hasNextPage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  // case FETCH_PRODUCTS_START:
  //   return {
  //     ...state,
  //     isLoading: true,
  //   };
  case FETCH_PRODUCT_SUCCESS:
    return {
      ...state,
      product: payload,
      isLoading: false,
    };
  // case FETCH_PRODUCTS_SUCCESS:
  //   return {
  //     ...state,
  //     products: payload.data.items,
  //     total: payload.total,
  //     isLoading: payload.isLoading,
  //     isError: payload.isError,
  //     error: payload.error,
  //     fetchNextPage: payload.fetchNextPage,
  //     isFetching: payload.isFetching,
  //     isFetchingNextPage: payload.isFetchingNextPage,
  //     hasNextPage: payload.hasNextPage,
  //   };
  case FETCH_PRODUCTS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
