import React from 'react';
import cn from 'classnames';

import Logo from '../ui/Logo';
import StaticMenu from './menu/StaticMenu';
import JoinButton from './menu/JoinButton';
import CartCounterButton from '../cart/CartCounterButton';

function Header() {
  return (
    <header
      className={cn('site-header-with-search h-14 md:h-16 lg:h-22 ', {
        'lg:!h-auto': false,
      })}
    >
      <div
        className={cn(
          'flex justify-between items-center w-full h-14 md:h-16 lg:h-22 px-4 lg:px-8 py-5 z-50 fixed bg-light bg-opacity-50 border-b border-border-200 shadow-sm transition-transform duration-300',
          {
            'lg:absolute lg:bg-transparent lg:shadow-none lg:border-0': false,
          }
        )}
      >
        <div className="flex items-center w-full lg:w-auto">
          <Logo className="mx-auto lg:mx-0" />
        </div>
        <ul className="hidden lg:flex items-center flex-shrink-0 space-s-10">
          <StaticMenu />
          <li><JoinButton /></li>
        </ul>
      </div>
      <CartCounterButton />
    </header>
  );
}

export default Header;
