import React from 'react';
import TreeMenu from './tree-menu/TreeMenu';
import Scrollbar from './scrollbar/Scrollbar';

function StickySidebarListCategories(props) {
  const { className } = props;

  return (
    <aside
      className={`lg:sticky lg:top-22 h-full xl:w-72 xl:block bg-light ${className}`}
    >
      <div className="max-h-full overflow-hidden">
        <Scrollbar
          className="w-full max-h-screen"
          style={{ height: 'calc(100vh - 5.35rem)' }}
        >
          <div className="px-5">
            <TreeMenu />
          </div>
        </Scrollbar>
      </div>
    </aside>
  );
}

export default StickySidebarListCategories;
