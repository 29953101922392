/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { CloseIcon } from '../icons';
import { useCart } from '../../redux/cart/cart.context';
import fadeInOut from '../../lib/motion/fade-in-out';
import { useSettings } from '../../settings/settings';
import usePrice from '../../hooks/usePrice';
import Counter from '../ui/Counter';

function CartItem({ item }) {
  const { t } = useTranslation('common');
  const {
    isInStock,
    clearItemFromCart,
    addItemToCart,
    removeItemFromCart
  } = useCart();

  const { placeholderImage } = useSettings();

  const { price } = usePrice({
    amount: item.price,
  });

  const { price: itemPrice } = usePrice({
    amount: item.itemTotal,
  });

  const { id, quantity, image } = item;

  const handleIncrement = (e) => {
    e.stopPropagation();
    addItemToCart(item, 1);
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    removeItemFromCart(id);
  };

  const outOfStock = !isInStock(id);

  return (
    <motion.div
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.25)}
      className="flex items-center py-4 px-4 sm:px-6 text-sm border-b border-solid border-border-200 border-opacity-75"
    >
      <div className="flex-shrink-0">
        <div />
        <Counter
          value={quantity}
          onDecrement={handleRemoveClick}
          onIncrement={handleIncrement}
          variant="pillVertical"
          disabled={outOfStock}
        />
      </div>

      <div className="w-10 sm:w-16 h-10 sm:h-16 flex items-center justify-center overflow-hidden bg-gray-100 mx-4 flex-shrink-0 relative">
        <img
          src={image || placeholderImage}
          alt={item.name}
        />
      </div>
      <div>
        <h3 className="font-bold text-heading">{item.name}</h3>
        <p className="my-2.5 font-semibold text-orange-400">{price}</p>
        <span className="text-xs text-body">
          {item.quantity}
          {' '}
          X
          {' '}
          {item.unit}
        </span>
      </div>
      <span className="ms-auto font-bold text-heading">{itemPrice}</span>
      <button
        type="button"
        className="w-7 h-7 ms-3 -me-2 flex items-center justify-center flex-shrink-0 rounded-full text-muted transition-all duration-200 focus:outline-none hover:bg-gray-100 focus:bg-gray-100 hover:text-red-600 focus:text-red-600"
        onClick={() => clearItemFromCart(id)}
      >
        <span className="sr-only">{t('Remove item')}</span>
        <CloseIcon className="w-3 h-3" />
      </button>
    </motion.div>
  );
}

export default CartItem;
