import React, { useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UploadIcon from '../icons/UploadIcon';
import Spinner from '../ui/spinner/Spinner';

export default function Uploader({
  onChange, files, handleSetFiles, name, onBlur
}) {
  const { t } = useTranslation('common');
  const loading = useSelector((state) => state.fileUploadReducer.images_isLoading);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      onChange(name, acceptedFiles);
      handleSetFiles(
        acceptedFiles.map((file) => Object.assign(file, {
          preview: URL.createObjectURL(file),
        }))
      );
    },
    [handleSetFiles, name, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: true,
    onDrop,
  });

  const thumbs = files.map((file) => (
    <div
      className="inline-flex flex-col overflow-hidden border border-border-100 rounded mt-2 me-2 relative"
      key={file.name}
    >
      <div className="flex items-center justify-center min-w-0 w-16 h-16 overflow-hidden">
        {/* eslint-disable */}
        <img src={file.preview} alt={file.name} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="upload">
      <div
        {...getRootProps({
          className:
            'border-dashed border-2 border-border-base h-36 rounded flex flex-col justify-center items-center cursor-pointer focus:border-accent-400 focus:outline-none',
        })}
      >
        <input
          {...getInputProps({
            name,
            onBlur,
          })}
        />
        <UploadIcon className="text-muted-light" />
        <p className="text-body text-sm mt-4 text-center">
          <span className="text-orange-400 font-semibold">
            {t('text-upload-highlight')}
          </span>{' '}
          {t('text-upload-message')} <br />
          <span className="text-xs text-body">{t('text-img-format')}</span>
        </p>
      </div>

      {(!!thumbs.length || loading) && (
        <aside className="flex flex-wrap mt-2">
          {!!thumbs.length && thumbs}
          {loading && (
            <div className="h-16 flex items-center mt-2 ms-2">
              <Spinner
                text={t('text-loading')}
                simple={true}
                className="w-6 h-6"
              />
            </div>
          )}
        </aside>
      )}
    </section>
  );
}
