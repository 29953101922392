/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setCategoryId } from '../../../redux/pages/action';
import { ExpandLessIcon, ExpandMoreIcon } from '../../icons';
import { useSettings } from '../../../settings/settings';

function TreeMenuItem(props) {
  const {
    className,
    item,
    depth = 0,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCategoryId = useSelector((state) => state.pagesReducer.selectedCategoryId);

  const { t } = useTranslation('common');
  const { lang } = useSettings();

  let expandIcon;
  const [isOpen, setOpen] = useState(false);
  const hasChildren = item.children && item.children.length > 0;

  const {
    id,
    localizeInfos,
    children: items,
    pageUrl
  } = item;

  const isActive = id === selectedCategoryId;

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  function toggleCollapse() {
    setOpen((prevValue) => !prevValue);
  }
  const { title = '' } = localizeInfos[lang] || '';

  function onClick() {
    navigate(`/?category=${pageUrl}`);
    toggleCollapse();
  }

  if (Array.isArray(items) && items.length) {
    expandIcon = !isOpen ? (
      <ExpandLessIcon className="w-3 h-3" />
    ) : (
      <ExpandMoreIcon className="w-3 h-3" />
    );
  }

  return (
    <>
      <motion.li
        initial={false}
        animate={{ backgroundColor: '#ffffff' }}
        onClick={onClick}
        className="py-1 rounded-md"
      >
        <button
          type="button"
          className={cn(
            'flex items-center w-full py-2 text-start outline-none text-body-dark font-semibold  focus:outline-none focus:ring-0 focus:text-orange-400 transition-all ease-in-expo',
            isActive ? 'text-orange-400' : 'text-body-dark',
            className || 'text-sm'
          )}
        >
          <span>{t(title)}</span>
          <span className="ms-auto">{expandIcon}</span>
        </button>
      </motion.li>
      <AnimatePresence initial={false}>
        {hasChildren && isOpen ? (
          <li>
            <motion.ul
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="ms-4 text-xs"
            >
              {items.map((currentItem, i) => {
                const childDepth = depth + 1;
                return (
                  <TreeMenuItem
                    key={`${currentItem.title}_${currentItem.id}_${i}`}
                    item={currentItem}
                    depth={childDepth}
                    className="text-sm text-body ms-2"
                  />
                );
              })}
            </motion.ul>
          </li>
        ) : null}
      </AnimatePresence>
    </>
  );
}

export default TreeMenuItem;
