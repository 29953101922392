/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import {
  FETCH_MENUS_START,
  FETCH_MENUS_FAIL,
  FETCH_MENUS_SUCCESS
} from './action';

const initialState = {
  menus: [],
  total: null,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_MENUS_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_MENUS_SUCCESS:
    return {
      ...state,
      menus: payload,
      isLoading: false,
    };
  case FETCH_MENUS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
