import { isEmpty } from 'lodash';

const getAttributeSetId = (attributesSets) => {
  if (isEmpty(attributesSets)) {
    return null;
  }
  const index = Object.keys(attributesSets).find((k) => k.startsWith('id'));
  return index in attributesSets ? attributesSets[index] : null;
};

export default getAttributeSetId;
