import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

const initialState = {
  searchTerm: '',
};

export const SearchContext = createContext(initialState);

SearchContext.displayName = 'SearchContext';

export function SearchProvider(props) {
  const { query } = props;
  const [searchTerm, updateSearchTerm] = useState('');

  useEffect(() => {
    if (query?.text) {
      updateSearchTerm(query.text);
    } else {
      updateSearchTerm('');
    }
  }, [query]);

  const value = useMemo(
    () => ({
      searchTerm,
      updateSearchTerm,
    }),
    [searchTerm]
  );

  return <SearchContext.Provider value={value} {...props} />;
}

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
