/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '../icons';
import { fetchDrawerType } from '../../redux/drawer/action';

export default function FilterBar() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function setDrawerView(props) {
    const { path, display, view } = props;
    navigate(path);
    dispatch(fetchDrawerType({ display, view }));
  }

  return (
    <div className="sticky top-14 md:top-16 lg:top-22 h-14 md:h-16 z-10 flex xl:hidden items-center justify-between py-3 px-5 lg:px-7 bg-light border-t border-b border-border-200">
      <button
        type="button"
        onClick={() => setDrawerView({
          display: true,
          view: 'FILTER_VIEW',
          path: '/'
        })}
        className="flex items-center h-8 md:h-10 py-1 md:py-1.5 px-3 md:px-4 text-sm md:text-base bg-gray-100 bg-opacity-90 rounded border border-border-200 font-semibold text-heading transition-colors duration-200 focus:outline-none hover:border-orange-400 focus:border-orange-400-hover hover:bg-orange-400 focus:bg-orange-400 hover:text-light focus:text-light"
      >
        <FilterIcon width="18" height="14" className="me-2" />
        {t('Show categories')}
      </button>
    </div>
  );
}
