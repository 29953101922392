/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useCart } from '../../redux/cart/cart.context';
import { fetchDrawerType } from '../../redux/drawer/action';
import usePrice from '../../hooks/usePrice';
import formatString from '../../utils/format-string';

import { CartCheckBagIcon } from '../icons';

function CartCounterButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { totalUniqueItems, total } = useCart();
  const { price: totalPrice } = usePrice({
    amount: total,
  });

  function handleCartSidebar() {
    dispatch(fetchDrawerType({ display: true, view: 'cart' }));
  }

  return (
    <button
      type="button"
      className="hidden product-cart lg:flex flex-col items-center justify-center p-3 pt-3.5 fixed top-1/2 -mt-12 end-0 z-40 shadow-900 rounded rounded-te-none rounded-be-none text-light text-sm font-semibold transition-colors duration-200 focus:outline-none hover:bg-orange-400 focus:bg-orange-400 bg-orange-400"
      onClick={handleCartSidebar}
    >
      <span className="flex pb-0.5">
        <CartCheckBagIcon className="flex-shrink-0" width={14} height={16} />
        <span className="flex ms-2">
          {formatString(totalUniqueItems, t('item'))}
        </span>
      </span>
      <span className="bg-light rounded w-full py-2 px-2 text-orange-400 mt-3">
        {totalPrice}
      </span>
    </button>
  );
}

export default CartCounterButton;
