import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '../../icons';

function AddToCartBtn({ onClick, disabled }) {
  const { t } = useTranslation('common');

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="group flex h-7 w-full items-center justify-between rounded bg-gray-100 text-xs text-body-light transition-colors hover:bg-orange-400 hover:text-light focus:border-accent focus:bg-orange-400 focus:text-light focus:outline-none md:h-9 md:text-sm"
    >
      <span className="flex-1">{t('Add to cart')}</span>
      <span className="grid h-7 w-7 place-items-center text-body-light bg-gray-200 transition-colors duration-200 group-hover:bg-orange-400 group-focus:bg-orange-400 ltr:rounded-tr ltr:rounded-br rtl:rounded-tl rtl:rounded-bl md:h-9 md:w-9">
        <PlusIcon className="w-4 h-4" />
      </span>
    </button>
  );
}

export default AddToCartBtn;
