/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { useSettings } from '../../settings/settings';

import Search from '../ui/search/Search';

function BannerWithSearch(props) {
  const {
    page,
    className,
  } = props;
  const { lang } = useSettings();

  const { t } = useTranslation('banner');
  const { localizeInfos } = page;
  const { title, content } = localizeInfos[lang];
  const block = page.blocks.find((b) => b.name === 'BannerWithSearch');
  const background = block?.attributesSets.image_id3[0].downloadLink;

  return (
    <div className={cn('min-h-screen', className)}>
      <div className="relative">
        <div className="-z-1 overflow-hidden">
          <div className="relative">
            <div className="relative w-full h-screen min-h-screen max-h-140">
              <img
                className="w-full h-auto min-h-screen object-cover"
                src={background || '/background.svg'}
                alt={title}
              />
              <div className="absolute inset-0 mt-8 flex w-full flex-col items-center justify-center p-5 text-center md:px-20 lg:space-y-10">
                <h1 className="text-4xl xl:text-5xl tracking-tight text-heading font-bold">
                  {title || t('Please add title')}
                </h1>
                <div className="text-base xl:text-lg text-heading mb-4">
                  {ReactHtmlParser(content) || t('Please add content')}
                </div>
                <div className="max-w-3xl w-full">
                  <Search label={t('Search')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerWithSearch;
