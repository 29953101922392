/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../settings/settings';

import usePrice from '../../hooks/usePrice';
import useProductImage from './ProductImage';

import AddToCart from './add-to-cart/AddToCart';
import { CartIcon } from '../icons';
import { productPlaceholder } from '../ui/placeholders';
import { useModalAction } from '../ui/modal/modalContext';

function ProductCard(props) {
  const {
    product,
    className = ''
  } = props;

  const { lang } = useSettings();
  const { t } = useTranslation('common');
  const {
    id,
    attributeValues,
    productType,
  } = product || {};

  const unit = 'pcs.';
  const { quantity } = attributeValues || {};

  const { localizeInfos } = product;
  const { title = '' } = localizeInfos[lang] || '';

  const productPrice = Number(product?.price);
  const salePrice = Number(product?.sale_price);

  const { price, basePrice, discount } = usePrice({
    amount: salePrice || productPrice,
    baseAmount: productPrice,
  });

  const img = attributeValues['7'].at(-1) || attributeValues.image;

  const imageUrl = useProductImage(img);
  const { openModal } = useModalAction();

  function handleProductQuickView() {
    return openModal('PRODUCT_DETAILS', { product });
  }

  return (
    <article
      className={cn(
        'product-card relative rounded border border-border-200 h-full bg-light overflow-hidden transition-shadow duration-200 hover:shadow-sm',
        className
      )}
    >
      <button
        onClick={handleProductQuickView}
        className="relative flex items-center justify-center w-full h-60"
        type="button"
        title={title}
      >
        <span className="sr-only">{t('text-product-image')}</span>
        <div className=" flex items-center justify-center overflow-hidden w-full h-60">
          <img
            src={imageUrl || productPlaceholder}
            alt={title}
            className="w-full product-image"
            loading="lazy"
          />
        </div>
        {discount && (
          <div className="absolute top-3 end-3 md:top-4 md:end-4 rounded-full text-xs leading-6 font-semibold px-1.5 sm:px-2 md:px-2.5 bg-yellow-500 text-light">
            {discount}
          </div>
        )}
      </button>
      {/* End of product image */}

      <div className="p-3 md:py-6 md:p-5 relative">
        <h3
          onClick={handleProductQuickView}
          type="button"
          className="text-heading text-sm font-semibold truncate mb-2"
        >
          {title}
        </h3>
        <p className="text-muted text-xs">{unit}</p>
        {/* End of product info */}

        <div className="flex items-center justify-between min-h-6 mt-7 md:mt-8 relative">
          {productType && productType.toLowerCase() === 'variable' ? (
            <>
              <div>
                <span className="text-orange-400 text-sm md:text-[15px] font-semibold">
                  {/* {minPrice} */}
                </span>
                <span> - </span>
                <span className="text-orange-400 text-sm md:text-[15px] font-semibold">
                  {/* {maxPrice} */}
                </span>
              </div>

              {Number(quantity) > 0 && (
                <button
                  type="button"
                  onClick={handleProductQuickView}
                  className="order-5 sm:order-4 py-2 px-3 sm:px-4 border-2 border-border-100 flex items-center justify-center sm:justify-start text-sm font-semibold rounded-full text-orange-400 hover:text-light bg-light hover:bg-orange-400 hover:border-accent transition-colors duration-300 focus:outline-none focus:bg-orange-400 focus:border-accent focus:text-light"
                >
                  <CartIcon className="w-4 h-4 me-2" />
                  <span>{t('text-cart')}</span>
                </button>
              )}
            </>
          ) : (
            <>
              <div className="relative mr-2">
                {basePrice && (
                  <del className="text-xs text-muted text-opacity-75 absolute -top-4 md:-top-5 italic">
                    {basePrice}
                  </del>
                )}
                <span className="text-orange-400 text-sm md:text-base font-semibold">
                  {price}
                </span>
              </div>

              {Number(quantity) > 0 && (
                <AddToCart
                  data={product}
                  // variant={}
                  counterVariant="single"
                />
              )}
            </>
          )}
          {Number(quantity) <= 0 && (
            <div className="bg-red-500 rounded text-xs text-light px-2 py-1">
              {t('Out of stock')}
            </div>
          )}

        </div>
      </div>
      <a
        className="edit-product"
        href={`https://demo.dev.oneentry.cloud/catalog/products-filter/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit
      </a>
    </article>
  );
}

export default ProductCard;
