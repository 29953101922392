const formFieldsType = {
  1: 'text',
  2: 'password',
  3: 'checkbox',
  4: 'radio',
  5: 'file',
  6: 'hidden',
  7: 'list',
  8: 'textField',
  9: 'spamProtection',
};

export default formFieldsType;
