import React from 'react';
import { useTranslation } from 'react-i18next';

function NotFoundPage(props) {
  const { page } = props;
  const { t } = useTranslation('common');
  const editLink = `https://demo.dev.oneentry.cloud/content/edit-page/${page.id}`;

  return (
    <>
      <section className="h-100 py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
        <header className="text-center mb-8">
          <h1 className="font-bold text-xl md:text-2xl xl:text-3xl">
            {t('404')}
          </h1>
        </header>
        <div className="max-w-screen-lg w-full mx-auto">
          {t('Error 404')}
        </div>
      </section>
      <a
        target="_blank"
        href={editLink}
        className="edit-page"
        rel="noreferrer"
      >
        Edit
      </a>
    </>
  );
}

export default NotFoundPage;
