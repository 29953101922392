/* eslint-disable default-param-last */
import { FETCH_DRAWER_TYPE } from './action';

const initialState = {
  drawer: { display: false, view: '' },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_DRAWER_TYPE:
    return {
      ...state,
      drawer: payload,
    };
  default:
    return { ...state };
  }
};
