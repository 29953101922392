/* eslint-disable no-unused-vars */
import React from 'react';

import { useCart } from '../../../redux/cart/cart.context';
import generateCartItem from '../../../redux/cart/generateCartItem';

import cartAnimation from '../../../lib/cartAnimation';
import AddToCartBtn from './AddToCartBtn';
import Counter from '../../ui/Counter';

function AddToCart(props) {
  const {
    data,
    variant,
    counterVariant,
    counterClass,
    variation,
    disabled,
  } = props;

  const {
    addItemToCart,
    removeItemFromCart,
    isInStock,
    getItemFromCart,
    isInCart,
  } = useCart();

  const item = generateCartItem(data, variation);

  const handleAddClick = (e) => {
    e.stopPropagation();
    addItemToCart(item, 1);
    if (!isInCart(item.id)) {
      cartAnimation(e);
    }
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    removeItemFromCart(item.id);
  };

  const outOfStock = isInCart(item.id) && !isInStock(item.id);

  return !isInCart(item.id) ? (
    <AddToCartBtn
      disabled={disabled || outOfStock}
      onClick={handleAddClick}
    />
  ) : (
    <Counter
      value={getItemFromCart(item.id).quantity}
      onDecrement={handleRemoveClick}
      onIncrement={handleAddClick}
      variant={counterVariant || variant}
      className={counterClass}
      disabled={outOfStock}
    />
  );
}

export default AddToCart;
