/* eslint-disable default-param-last */
import {
  FETCH_FORMS_START,
  FETCH_FORMS_FAIL,
  FETCH_FORM_SUCCESS,
  FETCH_FORMS_SUCCESS,
  FETCH_FORM_DATA,
} from './action';

const initialState = {
  forms: [],
  fullForms: [],
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_FORMS_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_FORMS_SUCCESS:
    return {
      ...state,
      forms: payload.items,
      total: payload.total,
      isLoading: false,
    };
  case FETCH_FORM_SUCCESS:
    return {
      ...state,
      fullForms: [
        ...state.fullForms,
        payload
      ],
      total: payload.total,
      isLoading: false,
    };
  case FETCH_FORMS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  case FETCH_FORM_DATA:
    return {
      ...state,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
