import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MobileNavigation from './MobileNavigation';

function SiteLayout({ component: Component, ...props }) {
  return (
    <div className="flex flex-col justify-between min-h-screen transition-colors duration-150 bg-gray-100">
      <Header />
      <Component {...props} />
      <MobileNavigation />
      <Footer />
    </div>
  );
}

export const getLayout = (page) => <SiteLayout>{page}</SiteLayout>;

export default SiteLayout;
