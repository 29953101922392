/* eslint-disable no-unused-vars */
import React from 'react';
// import { useRouter } from 'next/router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ArrowNarrowLeft } from '../icons';
import ROUTES from '../../settings/routes';
import { useModalAction } from './modal/modalContext';

function BackButton() {
  // const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { closeModal } = useModalAction();

  return (
    <button
      className="inline-flex items-center justify-center text-orange-400 font-semibold transition-colors hover:text-orange-400-hover focus:text-orange-400-hover focus:outline-none"
      // onClick={router.back}
      onClick={() => {
        closeModal();
      }}
      type="button"
    >
      <ArrowNarrowLeft
        className={cn('w-5 h-5 me-2 transform rotate-0')}
        // className={cn('w-5 h-5 me-2', {
        //   'transform rotate-180':
        //     router.locale === 'ar' || router.locale === 'he',
        // })}
        strokeWidth={1.7}
      />
      {t('Back')}
    </button>
  );
}

export default BackButton;
