/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import makeTree from '../../utils/makeTree';

import {
  FETCH_PAGES_START,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_FAIL,
} from './action';

const initialState = {
  pages: [],
  rootPages: [],
  catalogPages: [],
  selectedCategoryId: null,
  isLoading: false,
  err: null,
};

let newData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_PAGES_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_PAGES_SUCCESS:
    newData = makeTree(payload, 'parentId', null, 'position')
      .filter((page) => page?.id === payload.find((p) => p.pageUrl === 'catalog')?.id);
    return {
      ...state,
      pages: payload,
      catalogPages: newData,
      rootPages: payload.filter((page) => (page.depth === 0) && page.isVisible),
      isLoading: false,
    };
  case FETCH_PAGES_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
