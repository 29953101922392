import cn from 'classnames';
import React from 'react';

const classes = {
  root: 'inline-flex items-center justify-center flex-shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700',
  normal: 'bg-orange-500 text-light border border-transparent hover:bg-orange-600',
  custom: 'border border-transparent',
  outline:
    'border border-border-400 bg-transparent border-orange-500 text-body hover:text-light hover:bg-orange-500 hover:border-orange-500',
  loading: 'h-4 w-4 ms-2 rounded-full border-2 border-transparent border-t-2 animate-spin',
  disabled:
    'border border-border-base bg-gray-300 hover:bg-gray-300 border-border-400 text-body cursor-not-allowed',
  disabledOutline: 'border border-border-base text-muted cursor-not-allowed',
  small: 'px-3 py-0 h-9 text-sm h-10',
  medium: 'px-5 py-0 h-12',
  big: 'px-10 py-0 h-14',
};

function Button(props) {
  const {
    className,
    variant = 'normal',
    size = 'medium',
    children,
    active,
    loading = false,
    disabled = false,
    ...rest
  } = props;
  const classesName = cn(
    classes.root,
    {
      [classes.normal]: !disabled && variant === 'normal',
      [classes.disabled]: disabled && variant === 'normal',
      [classes.outline]: !disabled && variant === 'outline',
      [classes.disabledOutline]: disabled && variant === 'outline',
      [classes.small]: size === 'small',
      [classes.medium]: size === 'medium',
      [classes.big]: size === 'big',
    },
    className
  );

  return (
    <button
      type="button"
      aria-pressed={active}
      data-variant={variant}
      className={classesName}
      disabled={disabled}
      {...rest}
    >
      {children}
      {loading && (
        <span
          className={classes.loading}
          style={{
            borderTopColor: variant === 'outline' ? 'currentColor' : '#ffffff',
          }}
        />
      )}
    </button>
  );
}

export default Button;
