/* eslint-disable no-unused-vars */
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { PlusIcon, MinusIcon } from '../icons';

function Counter({
  value,
  onDecrement,
  onIncrement,
  className,
  disabled,
}) {
  const { t } = useTranslation('common');

  return (
    <div
      className={cn('flex overflow-hidden w-full h-7 md:h-9 bg-orange-400 text-light rounded', className)}
    >
      <button
        type="button"
        onClick={onDecrement}
        className="cursor-pointer p-2 transition-colors duration-200 focus:outline-none hover:bg-orange-400-hover"
      >
        <span className="sr-only">{t('Remove')}</span>
        <MinusIcon className="h-3 w-3 stroke-2.5" />
      </button>
      <div
        className="flex-1 flex items-center justify-center text-sm font-semibold"
      >
        {value}
      </div>
      <button
        type="button"
        onClick={onIncrement}
        disabled={disabled}
        className="cursor-pointer p-2 transition-colors duration-200 focus:outline-none hover:bg-orange-400-hover"
        title={disabled ? t('Out of stock') : ''}
      >
        <span className="sr-only">{t('Add')}</span>
        <PlusIcon className="h-3.5 w-3.5 md:h-4.5 md:w-4.5 stroke-2.5" />
      </button>
    </div>
  );
}

export default Counter;
