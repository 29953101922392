/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttributesProvider } from './attributes.context';

import ShortDetails from './ShortDetails';
import Details from './ProductDetails';
import RelatedProducts from './RelatedProducts';
import Spinner from '../../ui/loaders/spinner';

function Popup(props) {
  const {
    product,
    loading
  } = props;

  const { t } = useTranslation('common');
  // const [showStickyShortDetails] = useAtom(stickyShortDetailsAtom);

  const { id, relatedProducts, quantity } = product ?? {};

  if (loading || !product) {
    return (
      <div className="w-96 flex justify-center items-center h-96 bg-light relative">
        <Spinner text={t('loading')} />
      </div>
    );
  }

  return (
    <AttributesProvider>
      <article className="bg-light w-full max-w-6xl xl:min-w-[1152px] relative z-[51] md:rounded-xl">
        {/* Sticky bar */}
        {/* <ShortDetails
          product={product}
          isSticky
        /> */}
        {/* End of sticky bar */}
        <Details
          product={product}
          backBtn
          isModal
        />
        {relatedProducts?.length > 1 && (
          <div className="p-5 md:pb-10 lg:p-14 xl:p-16">
            <RelatedProducts
              products={relatedProducts}
              currentProductId={id}
            />
          </div>
        )}
      </article>
    </AttributesProvider>
  );
}

export default Popup;
