import React from 'react';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { PlusIcon, MinusIcon } from '../icons';
import heightCollapse from '../../lib/motion/height-collapse';
import { useSettings } from '../../settings/settings';

function AccordionItem(props) {
  const {
    item,
    i,
    expanded,
    setExpanded,
  } = props;

  const { lang } = useSettings();
  const isOpen = expanded;
  // active state style
  const activeClass = isOpen ? 'shadow-sm' : '';
  if (!item) {
    return;
  }
  const { localizeInfos } = item;
  const {
    title = '',
    content = '',
  } = localizeInfos[lang];

  return (
    <div
      className={cn(
        'border border-solid border-border-200 bg-light rounded mb-2.5 transition-all hover:border-border-base',
        activeClass
      )}
    >
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen === i ? false : i)}
        className="py-4 px-5 rounded cursor-pointer flex items-center justify-between transition-colors"
      >
        <h2 className="text-sm md:text-base font-semibold leading-relaxed text-heading">
          {title}
        </h2>
        {isOpen === i ? (
          <MinusIcon className="flex-shrink-0 stroke-2" width={18} height={18} />
        ) : (
          <PlusIcon className="flex-shrink-0 stroke-2" width={20} height={20} />
        )}
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen === i && (
          <motion.div
            key="content"
            initial="from"
            animate="to"
            exit="from"
            variants={heightCollapse()}
          >
            <div
              className="md:pt-1 pb-4 px-5 leading-7 text-sm md:text-base md:leading-loose text-body-dark"
            >
              {content}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default AccordionItem;
