/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchBox from './SearchBox';
import { useSearch } from '../../../redux/products/searchContext';

function Search({ label, ...props }) {
  const { t } = useTranslation();

  const { searchTerm, updateSearchTerm } = useSearch();

  const handleOnChange = (e) => {
    const { value } = e.target;
    updateSearchTerm(value);
  };

  const onSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    updateSearchTerm(value);
  };

  const clearSearch = () => {
    updateSearchTerm('');
  };

  return (
    <SearchBox
      label={label}
      onSubmit={onSearch}
      onClearSearch={clearSearch}
      onChange={handleOnChange}
      value={searchTerm}
      name="search"
      placeholder={t('Search...')}
      {...props}
    />
  );
}

export default Search;
