import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import TextArea from '../TextArea';
import Input from '../Input';
import Radio from '../radio/Radio';
import formFieldsType from '../../../../constants/formFieldsType';
import Uploader from '../../../uploader/Uploader';

function FormField(props) {
  const {
    name,
    variant,
    className,
    field,
    id,
    handleChange,
    inputState,
    onLoadImages,
    files,
    handleSetFiles,
  } = props;

  const {
    type,
    placeholder,
    title
  } = field;

  switch (type) {
  case 3:
    return (
      <div className="my-6 flex">
        <label className="text-body-dark font-semibold text-sm leading-none mr-3" htmlFor={id}>
          {title}
        </label>
        <input
          name={name}
          onChange={(e) => {
            handleChange({ target: { name: e.target.name, value: e.target.checked } }, title);
          }}
          id={id}
          checked={inputState[name].value || false}
          type={formFieldsType[type]}
          className="my-0"
        />
      </div>
    );
  case 5:
    return (
      <div className="my-6">
        <Uploader
          files={files}
          handleSetFiles={handleSetFiles}
          onChange={onLoadImages}
          name={name}
        />
      </div>
    );
  case 6:
    return <Input name={name} type={formFieldsType[type]} />;
  case 9:
    if (field.captchaKey) {
      if (field.captcha === 'reCaptchav2') {
        return (
          <div className={className}>
            <ReCAPTCHA sitekey={field.captchaKey} />
          </div>
        );
      }
      if (field.captcha === 'reCaptchav3') {
        return (
          <div>
            <GoogleReCaptchaProvider reCaptchaKey={field.captchaKey}>
              {/* eslint-disable-next-line no-console */}
              <GoogleReCaptcha onVerify={(token) => console.log(token)} />
            </GoogleReCaptchaProvider>
          </div>
        );
      }
      return null;
    }
    return null;
  case 8:
    return (
      <TextArea
        name={name}
        onChange={(e) => handleChange(e, title)}
        value={inputState[name]?.value || ''}
        label={title}
        variant={variant}
        className={className}
      />
    );
  case 4:
    return (
      <div className="my-6">
        {title && <p className="text-body-dark font-semibold text-sm leading-none">{title}</p>}
        <div className="flex">
          {field.radioList.map((item) => (
            <Radio
              onChange={(e) => handleChange(e, title)}
              id={item.id}
              name={name}
              value={item.listItemValue}
              checked={inputState[name].value === item.listItemValue}
              key={item.id}
              label={item.listItemKey}
              className="mt-3 mr-6"
              // error={t(error?.[name]?.message!)}
            />
          ))}
        </div>
      </div>
    );
  case 7:
    if (field.radioList !== undefined && field.radioList.length > 0) {
      return (
        <div>
          <label className="text-body-dark font-semibold text-sm leading-none mr-3" htmlFor={id}>
            {title}
          </label>
          <select
            name={name}
            onChange={(e) => handleChange(e, title)}
            id={id}
            className="text-body text-sm my-6"
            value={inputState[name].value || 'Не выбрано'}
          >
            <option value="Не выбрано">Не выбрано</option>
            {field.radioList.map((item) => (
              <option key={item.id} value={item.listItemValue}>
                {item.listItemKey}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return null;
  default:
    return (
      <Input
        onChange={(e) => handleChange(e, title)}
        value={inputState[name]?.value || ''}
        name={name}
        id={id}
        placeholder={placeholder}
        label={title}
        variant={variant}
        type={formFieldsType[type]}
        className={className}
      />
    );
  }
}

export default FormField;
