import React, {
  useMemo, createContext, useState, useContext
} from 'react';

const initialState = {
  siteTitle: 'OneEntry',
  siteSubtitle: 'Headless Content Management System',
  currency: 'USD',
  lang: 'ru-RU',
  locale: 'ru_RU',
  logo: '/logo.svg',
  placeholderImage: '/placeholders/product.svg',
};

export const SettingsContext = createContext(initialState);

SettingsContext.displayName = 'SettingsContext';

export function SettingsProvider({
  initialValue,
  ...props
}) {
  const [state, updateSettings] = useState(initialValue || initialState);
  const value = useMemo(
    () => ({
      ...state,
      updateSettings,
    }),
    [state]
  );
  return <SettingsContext.Provider value={value} {...props} />;
}

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
