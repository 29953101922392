/* eslint-disable no-unused-vars */
import { get, post } from '../../api/api';
import API_ENDPOINTS from '../../api/api-endpoints';

export const FETCH_FORMS_START = 'FETCH_FORMS_START';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORMS_FAIL = 'FETCH_FORMS_FAIL';
export const FETCH_FORM_DATA = 'FETCH_FORM_DATA';

const fetchFormsStart = () => ({
  type: FETCH_FORMS_START,
});

const fetchFormsSuccess = (data) => ({
  type: FETCH_FORMS_SUCCESS,
  payload: data,
});

const fetchFormSuccess = (data) => ({
  type: FETCH_FORM_SUCCESS,
  payload: data,
});

const fetchFormData = () => ({
  type: FETCH_FORM_DATA,
});

const fetchFormsFail = (err) => ({
  type: FETCH_FORMS_FAIL,
  payload: err,
});

export const getForms = () => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const { data } = await get(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.FORMS}?limit=1000&offset=0`);
    dispatch(fetchFormsSuccess(data));
  } catch (err) {
    dispatch(fetchFormsFail(err));
  }
};

export const getFormById = (id) => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const { data } = await get(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.FORMS}${id}`);
    dispatch(fetchFormSuccess(data));
  } catch (err) {
    dispatch(fetchFormsFail(err));
  }
};

export const postFormData = (data) => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    await post(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.FORM_DATA}`, data);
    dispatch(fetchFormData());
  } catch (err) {
    dispatch(fetchFormsFail(err));
  }
};
