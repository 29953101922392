import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '../Drawer';
import { fetchDrawerType } from '../../../../redux/drawer/action';

import CartSidebarView from '../../../cart/CartSidebarView';
import MobileCategoryMenu from '../../../layouts/mobile-menu/MobileCategoryMenu';
import MobileMainMenu from '../../../layouts/mobile-menu/MobileMainMenu';

function ManagedDrawer() {
  const dispatch = useDispatch();
  const { display, view } = useSelector((state) => state.drawerReducer.drawer);

  return (
    <Drawer
      open={display}
      onClose={() => dispatch(fetchDrawerType({ display: false, view: '' }))}
      variant={
        view === 'FILTER_VIEW' || view === 'MAIN_MENU_VIEW' || view === 'FILTER_LAYOUT_TWO_VIEW'
          ? 'left'
          : 'right'
      }
    >
      {view === 'cart' && <CartSidebarView />}
      {view === 'FILTER_VIEW' && <MobileCategoryMenu />}
      {view === 'MAIN_MENU_VIEW' && <MobileMainMenu />}
    </Drawer>
  );
}

export default ManagedDrawer;
