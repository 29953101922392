/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Element } from 'react-scroll';
import isEmpty from 'lodash/isEmpty';

import usePage from '../hooks/usePage';
import Spinner from '../components/ui/spinner/Spinner';

import FilterBar from '../components/layouts/FilterBar';
import BannerWithSearch from '../components/banners/BannerWithSearch';
import PromotionSlider from '../components/sliders/PromotionSlider';
import Categories from '../components/products/Categories';
import Products from '../components/products/Products';

function IndexPage({ page }) {
  const { p, isLoading } = usePage(page.id);

  if (isEmpty(p) || isLoading) return <Spinner />;

  return (
    <>
      <BannerWithSearch
        page={p}
        className="min-h-140 -mt-14 md:-mt-16 lg:-mt-24"
      />
      <PromotionSlider
        page={p}
      />
      <div className="flex flex-1 bg-gray-100">
        <div className="sticky top-22 h-full lg:w-[380px] hidden xl:block bg-gray-100">
          <Categories />
        </div>
        <main className="w-full xl:overflow-hidden block lg:mt-8 xl:ps-0 xl:pe-5">
          <FilterBar />
          <Element name="grid" className="px-4 xl:px-0">
            <Routes>
              <Route index element={<Products />} />
              <Route path="*" element={(<Spinner />)} />
            </Routes>
          </Element>
        </main>
        <a
          target="_blank"
          href={`https://demo.dev.oneentry.cloud/content/edit-page/${page.id}`}
          className="edit-page"
          rel="noreferrer"
        >
          Edit
        </a>
      </div>
    </>
  );
}

export default IndexPage;
