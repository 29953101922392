/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DrawerWrapper from '../../ui/drawer/drawer-wrapper/DrawerWrapper';
import { fetchDrawerType } from '../../../redux/drawer/action';
import { useSettings } from '../../../settings/settings';

export default function MobileMainMenu(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useSettings();

  const menus = useSelector((state) => state.menusReducer.menus);
  const pages = menus.find((menu) => menu.id === 1)?.pages;

  function handleClick(path) {
    navigate(path);
    dispatch(fetchDrawerType({ display: false, view: '' }));
  }

  return (
    <DrawerWrapper>
      <ul className="flex-grow p-8">
        {pages?.map((page) => {
          const { pageUrl, localizeInfos } = page;
          const { title, content } = localizeInfos[lang];
          return (
            <li key={`${pageUrl}${title}`}>
              <button
                type="button"
                onClick={() => handleClick(`/${pageUrl}`)}
                className="font-semibold text-heading flex items-center transition duration-200 no-underline hover:text-orange-400 focus:text-orange-400"
              >
                {title}
              </button>
            </li>
          );
        })}
      </ul>
    </DrawerWrapper>
  );
}
