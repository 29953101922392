import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, LayoutGroup } from 'framer-motion';
import { useDispatch } from 'react-redux';

import { useCart } from '../../redux/cart/cart.context';
import usePrice from '../../hooks/usePrice';

import formatString from '../../utils/format-string';
import fadeInOut from '../../lib/motion/fade-in-out';
import CartItem from './CartItem';

import { fetchDrawerType } from '../../redux/drawer/action';

import { CartCheckBagIcon, EmptyCartIcon, CloseIcon } from '../icons';

function CartSidebarView() {
  const { t } = useTranslation('common');
  const { items, totalUniqueItems, total } = useCart();
  const dispatch = useDispatch();
  const { price: totalPrice } = usePrice({
    amount: total,
  });

  return (
    <section className="flex flex-col h-full relative">
      <header className="fixed max-w-md w-full top-0 z-10 bg-light py-4 px-6 flex items-center justify-between border-b border-border-200 border-opacity-75">
        <div className="flex text-orange-400 font-semibold">
          <CartCheckBagIcon className="flex-shrink-0" width={24} height={22} />
          <span className="flex ms-2">
            {formatString(totalUniqueItems, t('item'))}
          </span>
        </div>
        <button
          type="button"
          onClick={() => dispatch(fetchDrawerType({ display: false, view: '' }))}
          className="w-7 h-7 ms-3 -me-2 flex items-center justify-center rounded-full text-muted bg-gray-100 transition-all duration-200 focus:outline-none hover:bg-orange-400 focus:bg-orange-400 hover:text-light focus:text-light"
        >
          <span className="sr-only">{t('Close')}</span>
          <CloseIcon className="w-3 h-3" />
        </button>
      </header>
      {/* End of cart header */}

      <LayoutGroup>
        <motion.div layout className="flex-grow pt-16">
          {items.length > 0 ? (
            items?.map((item) => <CartItem item={item} key={item.id} />)
          ) : (
            <motion.div
              layout
              initial="from"
              animate="to"
              exit="from"
              variants={fadeInOut(0.25)}
              className="h-full flex flex-col items-center justify-center"
            >
              <EmptyCartIcon width={140} height={176} />
              <h4 className="mt-6 text-base font-semibold">
                {t('Cart is empty')}
              </h4>
            </motion.div>
          )}
        </motion.div>
      </LayoutGroup>
      {/* End of cart items */}

      <footer className="sticky left-0 bottom-0 w-full py-5 px-6 z-10 bg-light">
        <button
          type="button"
          className="flex justify-between w-full h-12 md:h-14 p-1 text-sm font-bold bg-orange-400 rounded-full shadow-700 transition-colors focus:outline-none hover:bg-orange-400 focus:bg-orange-400"
          // onClick={handleCheckout}
        >
          <span className="flex flex-1 items-center h-full px-5 text-light">
            {t('Checkout')}
          </span>
          <span className="flex items-center flex-shrink-0 h-full bg-light text-orange-400 rounded-full px-5">
            {totalPrice}
          </span>
        </button>
      </footer>
      {/* End of footer */}
    </section>
  );
}

export default CartSidebarView;
