/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
// import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
// import Image from 'next/image';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import ROUTES from '../../../settings/routes';
// import { Product } from '@framework/types';
import { productPlaceholder } from '../../ui/placeholders';
import isVariationSelected from '../variations/isVariationSelected';
import getVariations from '../variations/getVariations';
import { useModalAction } from '../../ui/modal/modalContext';
import usePrice from '../../../hooks/usePrice';
import VariationGroups from './VariationGroups';
import VariationPrice from './VariationPrice';
import AddToCart from '../add-to-cart/AddToCart';
import { useAttributes } from './attributes.context';

function ShortDetails(props) {
  const { product, isSticky } = props;
  // const router = useRouter();
  const { t } = useTranslation('common');

  const { closeModal } = useModalAction();
  const { attributes } = useAttributes();

  const {
    localizeInfos,
    price,
    name,
    slug,
    image,
    unit,
    quantity,
    minPrice,
    maxPrice
  } = product ?? {};

  const navigate = (path) => {
    // router.push(path);
    closeModal();
  };

  const { price_, basePrice, discount } = usePrice({
    amount: product?.sale_price ? product?.sale_price : !product?.price,
    baseAmount: !product?.price,
  });

  const variations = useMemo(() => getVariations(product?.variations), [product?.variations]);

  const isSelected = isVariationSelected(variations, attributes);

  let selectedVariation = {};
  if (isSelected) {
    selectedVariation = product?.variation_options?.find((o) => isEqual(
      o.options.map((v) => v.value).sort(),
      Object.values(attributes).sort()
    ));
  }

  const hasVariations = !isEmpty(variations);

  return (
    <div
      className={cn(
        'max-w-6xl h-auto w-full hidden md:block bg-light fixed top-0 left-1/2 transform -translate-x-1/2 z-50 px-8 py-6 shadow transition-all duration-300',
        {
          'invisible opacity-0 -translate-y-1/2': !isSticky,
          'visible opacity-100 translate-y-0': isSticky,
        }
      )}
    >
      <div className="flex items-center">
        <div
          className={cn(
            'border border-border-200 border-opacity-70 rounded relative flex items-center justify-center overflow-hidden flex-shrink-0',
            {
              'w-28 h-28': !hasVariations,
              'w-40 lg:w-52 h-40 lg:h-52': hasVariations,
            }
          )}
        >
          <img
            src={image?.original || productPlaceholder}
            alt={name}
            className="product-image"
          />
        </div>

        <div className="px-8 flex flex-col justify-center me-auto overflow-hidden">
          <h3
            className="font-semibold text-lg lg:text-xl tracking-tight text-heading truncate cursor-pointer transition-colors hover:text-orange-400"
            onClick={() => navigate(`${ROUTES.PRODUCT}/${slug}`)}
            title={name}
          >
            {name}
          </h3>

          {unit && !hasVariations ? (
            <span className="text-sm font-normal text-body mt-2 block">
              {unit}
            </span>
          ) : (
            <span className="flex items-center mt-2">
              {hasVariations && (
                <VariationPrice
                  selectedVariation={selectedVariation}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                />
              )}
            </span>
          )}
        </div>

        <div
          className={cn('w-full flex flex-shrink-0', {
            'max-w-max': !hasVariations,
            'max-w-[40%]': hasVariations,
          })}
        >
          {!hasVariations && (
            <span className="me-8 flex items-center ">
              <ins className="text-xl lg:text-2xl font-semibold text-orange-400 no-underline">
                {price}
              </ins>
              {basePrice && (
                <del className="text-sm lg:text-base font-normal text-muted ms-2">
                  {basePrice}
                </del>
              )}
            </span>
          )}

          <div className="w-full">
            <div
              className={cn('flex flex-col overflow-y-auto justify-center', {
                'h-[140px]': hasVariations,
              })}
            >
              <VariationGroups variations={variations} />
            </div>

            <div className={cn({ 'mt-4': hasVariations })}>
              {quantity > 0 ? (
                <AddToCart
                  data={product}
                  variant="big"
                  variation={selectedVariation}
                  disabled={selectedVariation?.is_disable || !isSelected}
                />
              ) : (
                <div className="bg-red-500 rounded text-sm text-light px-3 py-2">
                  {t('Out of stock')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortDetails;
