import API_ENDPOINTS from '../../api/api-endpoints';
import { get } from '../../api/api';

export const FETCH_PAGES_START = 'FETCH_PAGES_START';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAIL = 'FETCH_PAGES_FAIL';

const fetchPagesStart = () => ({
  type: FETCH_PAGES_START,
});

const fetchPagesSuccess = (data) => ({
  type: FETCH_PAGES_SUCCESS,
  payload: data,
});

const fetchPagesFail = (err) => ({
  type: FETCH_PAGES_FAIL,
  payload: err,
});

export const getPages = () => async (dispatch) => {
  dispatch(fetchPagesStart());
  try {
    const url = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PAGES}`;
    const { data } = await get(url);
    dispatch(fetchPagesSuccess(data));
  } catch (err) {
    console.log(err);
    dispatch(fetchPagesFail(err));
  }
};

export const setCategoryId = () => async (dispatch) => {
  dispatch(fetchPagesStart());
};
