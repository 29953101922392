/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './settings/i18n';

import ManagedDrawer from './components/ui/drawer/managed-drawer/ManagedDrawer';
import ManagedModal from './components/ui/modal/ManagedModal';

import SiteLayout from './components/layouts/SiteLayout';
import * as layouts from './pages';

import { getPages } from './redux/pages/action';
import { getTemplates } from './redux/templates/action';
import { getMenus } from './redux/menus/action';

import Spinner from './components/ui/spinner/Spinner';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMenus());
    dispatch(getPages());
    dispatch(getTemplates());
  }, [dispatch]);

  const rootPages = useSelector((state) => state.pagesReducer.rootPages);
  const templates = useSelector((state) => state.templatesReducer.templates);
  const allTemplates = Object.keys(templates).reduce((res, v) => res.concat(templates[v]), []);

  return (
    <div className="App">
      <Routes>
        {rootPages?.map((page, i) => {
          const layout = layouts[allTemplates?.find((template) => template.marker === page.templateIdentifier)?.marker];

          const { pageUrl } = page;
          let path = '';
          if (pageUrl === 'home') {
            path = '/';
          } else if (pageUrl === '404') {
            path = '*';
          } else {
            path = pageUrl;
          }

          if (!layout) return;

          return (
            <Route
              key={i}
              path={path}
              element={(
                <SiteLayout
                  component={layout}
                  page={page}
                />
              )}
            />
          );
        })}
        <Route path="*" element={(<Spinner />)} />
      </Routes>
      <ManagedModal />
      <ManagedDrawer />
    </div>
  );
}

export default App;
