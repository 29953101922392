/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Accordion from '../../components/accordion/Accordion';
import ensure from '../../utils/ensure';
import { useSettings } from '../../settings/settings';

function HelpPage(props) {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { lang } = useSettings();

  const pages = useSelector((state) => state.pagesReducer.pages);
  const page = ensure(pages?.find((item) => `/${item.pageUrl}` === location.pathname));
  const children = pages.filter((p) => p.parentId === page.id);

  if (isEmpty(page) || isEmpty(children)) {
    return;
  }

  const { localizeInfos } = page;
  const { menuTitle = '' } = localizeInfos[lang];

  return (
    <>
      <section className="py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
        <header className="text-center mb-8">
          <h1 className="font-bold text-xl md:text-2xl xl:text-3xl">
            {t(menuTitle)}
          </h1>
        </header>
        <div className="max-w-screen-lg w-full mx-auto">
          <Accordion items={children} />
        </div>
      </section>
      <a
        target="_blank"
        href={`https://demo.dev.oneentry.cloud/content/edit-page/${page.id}`}
        className="edit-page"
        rel="noreferrer"
      >
        Edit
      </a>
    </>
  );
}

export default HelpPage;
