/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { scroller, Element } from 'react-scroll';
import { Waypoint } from 'react-waypoint';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import Truncate from '../../ui/Truncate';

import ROUTES from '../../../settings/routes';
import { useSettings } from '../../../settings/settings';

import { useModalAction } from '../../ui/modal/modalContext';
import { useAttributes } from './attributes.context';
import { productPlaceholder } from '../../ui/placeholders';

import ThumbsCarousel from '../../ui/ThumbsCarousel';
import BackButton from '../../ui/BackButton';
import AddToCart from '../add-to-cart/AddToCart';
import CategoryBadges from './CategoryBadges';

import usePrice from '../../../hooks/usePrice';
import useProductImage from '../ProductImage';

import VariationPrice from './VariationPrice';
import getVariations from '../variations/getVariations';
import isVariationSelected from '../variations/isVariationSelected';

// import VariationGroups from './variation-groups';

function ProductDetails(props) {
  const {
    product,
    backBtn,
    isModal = false,
  } = props;

  const {
    id,
    localizeInfos,
    attributeValues,
    categories,
    gallery,
    type,
    unit,
  } = product ?? {};

  const { lang } = useSettings();
  const { t } = useTranslation('common');
  // const [showStickyShortDetails, setShowStickyShortDetails] = useState(false);

  const { quantity } = attributeValues;
  const { title, content } = localizeInfos[lang];
  const img = attributeValues['7'].at(-1) || attributeValues.image;
  const imageUrl = useProductImage(img);

  // const router = useRouter();
  const { closeModal } = useModalAction();
  const { attributes } = useAttributes();

  const productPrice = Number(product?.price);
  const salePrice = Number(product?.sale_price);

  const { price, basePrice, discount } = usePrice({
    amount: salePrice || productPrice,
    baseAmount: productPrice,
  });

  const navigate = (path) => {
  //   router.push(path);
    closeModal();
  };

  const variations = useMemo(
    () => getVariations(product?.variations),
    [product?.variations]
  );

  const isSelected = isVariationSelected(variations, attributes);
  let selectedVariation = {};
  if (isSelected) {
    selectedVariation = product?.variation_options?.find((o) => isEqual(
      o.options.map((v) => v.value).sort(),
      Object.values(attributes).sort()
    ));
  }

  const scrollDetails = () => {
    scroller.scrollTo('details', {
      smooth: true,
      offset: -80,
    });
  };

  const onWaypointPositionChange = ({ currentPosition }) => {
    if (!currentPosition || currentPosition === 'above') {
      // setShowStickyShortDetails(true);
    }
  };
  const hasVariations = !isEmpty(variations);

  return (
    <article className="rounded-lg bg-light">
      <div className="flex flex-col md:flex-row border-b border-border-200 border-opacity-70">
        <div className="md:w-1/2 p-6 pt-10 lg:p-14 xl:p-16">
          <div className="flex items-center justify-between mb-8 lg:mb-10">
            {backBtn && <BackButton />}
            {discount && (
              <div className="rounded-full text-xs leading-6 font-semibold px-3 bg-yellow-500 text-light ms-auto">
                {discount}
              </div>
            )}
          </div>

          <div className="product-gallery h-full">
            {gallery?.length ? (
              <ThumbsCarousel gallery={gallery} />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={imageUrl ?? productPlaceholder}
                  alt={title}
                  width={450}
                  height={450}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-start md:w-1/2 p-5 pt-10 lg:p-14 xl:p-16">
          <Waypoint
            // onLeave={() => setShowStickyShortDetails(true)}
            // onEnter={() => setShowStickyShortDetails(false)}
            onPositionChange={onWaypointPositionChange}
          >
            <div className="w-full">
              <h1
                className={classNames(
                  'font-semibold text-lg md:text-xl xl:text-2xl tracking-tight text-heading',
                  {
                    'cursor-pointer transition-colors hover:text-orange-400':
                      isModal,
                  }
                )}
                {...(isModal && {
                  onClick: () => navigate(`${ROUTES.PRODUCT}/${id}`),
                })}
              >
                {title}
              </h1>

              {unit && !hasVariations && (
                <span className="text-sm font-normal text-body mt-2 md:mt-3 block">
                  {unit}
                </span>
              )}

              {content && (
                <div className="mt-3 md:mt-4 text-body text-sm leading-7">
                  <Truncate
                    character={150}
                    {...(!isModal && {
                      onClick: () => scrollDetails(),
                      compressText: 'See more',
                    })}
                  >
                    {content}
                  </Truncate>
                </div>
              )}

              {hasVariations ? (
                <>
                  <div className="my-5 md:my-10 flex items-center">
                    <VariationPrice
                      // selectedVariation={selectedVariation}
                      minPrice={product.min_price}
                      maxPrice={product.max_price}
                    />
                  </div>
                  <div>
                    {/* <VariationGroups variations={variations} /> */}
                  </div>
                </>
              ) : (
                <span className="my-5 md:my-10 flex items-center">
                  <ins className="text-2xl md:text-3xl font-semibold text-orange-400 no-underline">
                    {price}
                  </ins>
                  {basePrice && (
                    <del className="text-sm md:text-base font-normal text-muted ms-2">
                      {basePrice}
                    </del>
                  )}
                </span>
              )}

              <div className="mt-4 md:mt-6 flex flex-col lg:flex-row items-center">
                <div className="mb-3 lg:mb-0 w-full lg:max-w-[400px]">
                  {Number(quantity) > 0 && (
                    <AddToCart
                      data={product}
                      // variant="big"
                      // variation={selectedVariation}
                      // disabled={selectedVariation?.is_disable || !isSelected}
                    />
                  )}
                </div>

                {!hasVariations && (
                  Number(quantity) > 0 ? (
                    <span className="text-base text-body whitespace-nowrap lg:ms-7">
                      {quantity}
                      {' '}
                      {t('available')}
                    </span>
                  ) : (
                    <div className="text-base text-red-500 whitespace-nowrap lg:ms-7">
                      {t('Out of stock')}
                    </div>
                  )
                )}
                {!isEmpty(selectedVariation) && (
                  <span className="text-base text-body whitespace-nowrap lg:ms-7">
                    {selectedVariation?.is_disable || selectedVariation.quantity === 0 ? t('Out of stock') : `${selectedVariation.quantity} ${t('available')}`}
                  </span>
                )}
              </div>
            </div>
          </Waypoint>

          {categories?.length && (
            <CategoryBadges
              categories={categories}
              basePath={`/${type?.slug}`}
              onClose={closeModal}
            />
          )}

        </div>
      </div>

      <Element
        name="details"
        className="py-4 px-5 lg:px-16 lg:py-14 border-b border-border-200 border-opacity-70"
      >
        <h2 className="text-lg text-heading tracking-tight font-semibold mb-4 md:mb-6">
          {title}
        </h2>
        <p className="text-sm text-body">{content}</p>
      </Element>
    </article>
  );
}

export default ProductDetails;
