/* eslint-disable no-unused-vars */
import React from 'react';

import SidebarVerticalCategories from '../layouts/SidebarVerticalCategories';

function Categories(props) {
  const { className } = props;
  return <SidebarVerticalCategories className={className} />;
}

export default Categories;
