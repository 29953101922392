import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageById } from '../redux/page/action';

function usePage(id) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.pageReducer);
  const { pages, isLoading } = data;
  const p = pages.find((item) => item.id === id);

  useEffect(() => {
    if (p) return;
    dispatch(getPageById(id));
  }, [dispatch, id, p]);

  return { p, isLoading };
}

export default usePage;
