/* eslint-disable no-unused-vars */
import React from 'react';
// import { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon, CloseIcon } from '../../icons';

function SearchBox(props) {
  const {
    className,
    label,
    onSubmit,
    onClearSearch,
    value,
    ...rest
  } = props;

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="rounded md:rounded-lg flex relative h-14 shadow-900">
        <span className="w-full h-full flex relative">
          <label htmlFor={label} className="sr-only">
            {label}
          </label>
          <input
            id={label}
            type="text"
            value={value}
            autoComplete="off"
            className="w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-heading text-sm placeholder-gray-500 overflow-hidden rounded-lg focus:outline-none focus:ring-0 bg-light ps-6 pe-14 rounded-tr-none rounded-br-none  border border-e-0 border-transparent focus:border-accent"
            {...rest}
          />
          {value && (
            <button
              type="button"
              onClick={onClearSearch}
              className="cursor-pointer h-full w-10 right-0 md:w-14 flex items-center justify-center absolute text-body transition-colors duration-200 focus:outline-none hover:text-orange-400-hover focus:text-orange-400-hover"
            >
              <span className="sr-only">{t('Clear search results')}</span>
              <CloseIcon className="w-3.5 h-3.5 md:w-3 md:h-3" />
            </button>
          )}
        </span>
        <button
          type="submit"
          className="flex h-full min-w-[143px] items-center justify-center rounded-lg px-8 font-semibold text-light transition-colors duration-200 ltr:rounded-tl-none ltr:rounded-bl-none rtl:rounded-tr-none rtl:rounded-br-none focus:outline-none hover:bg-orange-400 focus:bg-orange-400 bg-orange-400"
        >
          <SearchIcon
            className="w-4 h-4 me-2.5"
          />
          {t('Search')}
        </button>
      </div>
    </form>
  );
}

export default SearchBox;
