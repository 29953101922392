/* eslint-disable default-param-last */
import {
  FETCH_ATTRIBUTES_SETS_START,
  FETCH_ATTRIBUTES_SETS_SUCCESS,
  FETCH_ATTRIBUTES_SETS_FAIL,
} from './action';

const initialState = {
  attributesSets: {
    items: [],
    total: 0,
  },
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_ATTRIBUTES_SETS_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_ATTRIBUTES_SETS_SUCCESS:
    return {
      ...state,
      attributesSets: payload,
      isLoading: false,
    };
  case FETCH_ATTRIBUTES_SETS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
