import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ProductCard from '../ProductCard';

function RelatedProducts(props) {
  const {
    products,
    currentProductId,
    gridClassName,
  } = props;
  const { t } = useTranslation('common');

  return (
    <>
      <h2 className="text-lg text-heading tracking-tight font-semibold mb-6">
        {t('text-related-products')}
      </h2>
      <div
        className={cn(
          'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4',
          gridClassName
        )}
      >
        {products?.map((item, idx) => {
          if (currentProductId === item.id) {
            return null;
          }
          return (
            <ProductCard product={item} key={idx} cardType={item?.type?.slug} />
          );
        })}
      </div>
    </>
  );
}

export default RelatedProducts;
