import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import store from './redux/Store';
import { SearchProvider } from './redux/products/searchContext';
import { ModalProvider } from './components/ui/modal/modalContext';
import { CartProvider } from './redux/cart/cart.context';
import App from './app';

// import 'react-toastify/dist/ReactToastify.css';
import './assets/css/main.css';

const queryClient = new QueryClient();

function Root() {
  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SearchProvider>
            <ModalProvider>
              <CartProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CartProvider>
            </ModalProvider>
          </SearchProvider>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);
