/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import {
  FETCH_PAGE_START,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAIL,
} from './action';

const initialState = {
  pages: [],
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_PAGE_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_PAGE_SUCCESS:
    return {
      ...state,
      pages: state.pages.findIndex((page) => page.id === payload.id) === -1 ? [...state.pages, payload] : state.pages,
      isLoading: false,
    };
  case FETCH_PAGE_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
