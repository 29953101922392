import React from 'react';
import DrawerWrapper from '../../ui/drawer/drawer-wrapper/DrawerWrapper';
import Categories from '../../products/Categories';

function MobileCategoryMenu() {
  return (
    <DrawerWrapper>
      <div className="h-full max-h-full overflow-hidden">
        <Categories className="block" />
      </div>
    </DrawerWrapper>
  );
}

export default MobileCategoryMenu;
