import axios from 'axios';

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}content/`,
    });
  }

  post = async (url, data, params) => this.client.post(url, data, params);

  get = async (url, params = {}) => this.client.get(url, params);

  put = async (url, data, params) => this.client.put(url, data, params);

  remove = async (url, params = {}) => this.client.delete(url, params);
}

const api = new Api();

api.client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.log('Network error');
    }

    return Promise.reject(error);
  },
);

export const {
  post, get, put, remove,
} = api;

export default api;
