/* eslint-disable no-unused-vars */
import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import NotFound from '../ui/not-found/NotFound';
import StickySidebarListCategories from '../ui/StickySidebarListCategories';
import CategoriesLoader from '../ui/loaders/CategoriesLoader';

function SidebarVerticalCategories(props) {
  const { className = '' } = props;
  const { catalogPages, isLoading, err } = useSelector((state) => state.pagesReducer);

  if (isLoading) {
    return (
      <div className="hidden xl:block">
        <div className="w-full h-52 flex justify-center mt-8 px-2">
          <CategoriesLoader />
        </div>
      </div>
    );
  }

  if (err || isEmpty(catalogPages)) {
    return (
      <div className="w-full bg-gray-100 ">
        <div className="min-h-full pt-6 pb-8 px-9 lg:p-8">
          <NotFound text="No categories" className="h-96" />
        </div>
      </div>
    );
  }

  return (
    <div className={cn('w-full bg-gray-100', className)}>
      <div className="px-4 lg:p-8">
        <StickySidebarListCategories className="py-0" />
      </div>
    </div>
  );
}

export default SidebarVerticalCategories;
