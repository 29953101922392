import isEmpty from 'lodash/isEmpty';

function generateCartItem(item, variation) {
  const {
    id,
    localizeInfos,
    slug,
    price,
    salePrice,
    attributeValues,
    unit,
    lang = 'ru-RU'
  } = item;

  const { quantity } = attributeValues;
  const imageUrl = attributeValues['7'].at(-1)?.downloadLink;
  const { title } = localizeInfos[lang]?.title || '';

  if (!isEmpty(variation)) {
    return {
      id: `${id}.${variation.id}`,
      productId: id,
      name: `${title} - ${variation.title}`,
      slug,
      unit,
      stock: variation.quantity,
      price: variation.sale_price ? variation.sale_price : variation.price,
      image: imageUrl,
      variationId: variation.id,
    };
  }

  return {
    id,
    name: title,
    slug,
    unit,
    image: imageUrl,
    stock: quantity,
    price: salePrice || price,
  };
}
export default generateCartItem;
