import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchDrawerType } from '../../../../redux/drawer/action';

import Logo from '../../Logo';
import CloseIcon from '../../../icons/CloseIcon';

function DrawerWrapper({ children }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-5 border-b border-border-200 border-opacity-75">
        <Logo className="w-24 md:w-auto" />
        <button
          type="button"
          onClick={() => dispatch(fetchDrawerType({ display: false, view: '' }))}
          className="w-7 h-7 flex items-center justify-center rounded-full text-body bg-gray-200 transition-all duration-200 focus:outline-none hover:bg-orange-400 focus:bg-orange-400 hover:text-light focus:text-light"
        >
          <span className="sr-only">{t('Close')}</span>
          <CloseIcon className="w-2.5 h-2.5" />
        </button>
      </div>
      {/* End of header part */}

      {children}

      {/* End of menu part */}
    </div>
  );
}

export default DrawerWrapper;
