/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import API_ENDPOINTS from '../../api/api-endpoints';
import { get } from '../../api/api';

export const FETCH_MENUS_START = 'FETCH_MENUS_START';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAIL = 'FETCH_MENUS_FAIL';

const fetchMenusStart = () => ({
  type: FETCH_MENUS_START,
});

export const fetchMenusSuccess = (data) => ({
  type: FETCH_MENUS_SUCCESS,
  payload: data,
});

const fetchMenusFail = (err) => ({
  type: FETCH_MENUS_FAIL,
  payload: err,
});

export const getMenus = (props) => async (dispatch) => {
  dispatch(fetchMenusStart());
  try {
    const link = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.MENUS}`;
    const result = await get(link);
    dispatch(fetchMenusSuccess(result.data));
  } catch (err) {
    dispatch(fetchMenusFail(err));
  }
};
