/* eslint-disable default-param-last */
import { FILE_UPLOAD_START, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAIL } from './action';

const initialState = {
  images: [],
  images_isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FILE_UPLOAD_START:
    return {
      ...state,
      images_isLoading: true,
    };
  case FILE_UPLOAD_SUCCESS:
    return {
      ...state,
      images: payload,
      images_isLoading: false,
    };
  case FILE_UPLOAD_FAIL:
    return {
      ...state,
      err: payload,
      images_isLoading: false,
    };
  default:
    return { ...state };
  }
};
