import { useTranslation } from 'react-i18next';

const localeRTLList = ['ar', 'he'];

function useIsRTL() {
  const { i18n } = useTranslation();

  if (localeRTLList.includes(i18n.language)) {
    return { isRTL: true, alignLeft: 'right', alignRight: 'left' };
  }
  return { isRTL: false, alignLeft: 'left', alignRight: 'right' };
}

export default useIsRTL;
