import React from 'react';

function SearchIcon(props) {
  return (
    <svg viewBox="0 0 17.048 18" {...props}>
      <path
        d="M380.321,383.992l3.225,3.218c.167.167.341.329.5.506a.894.894,0,1,1-1.286,1.238c-1.087-1.067-2.179-2.131-3.227-3.236a.924.924,0,0,0-1.325-.222,7.509,7.509,0,1,1-3.3-14.207,7.532,7.532,0,0,1,6,11.936C380.736,383.462,380.552,383.685,380.321,383.992Zm-5.537.521a5.707,5.707,0,1,0-5.675-5.72A5.675,5.675,0,0,0,374.784,384.513Z"
        transform="translate(-367.297 -371.285)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SearchIcon;
