import React, {
  createContext, useState, useMemo, useContext
} from 'react';

const initialState = {};
export const AttributesContext = createContext(initialState);

AttributesContext.displayName = 'AttributesContext';

export function AttributesProvider(props) {
  const [state, dispatch] = useState(initialState);
  const value = useMemo(
    () => ({ attributes: state, setAttributes: dispatch }),
    [state]
  );
  return <AttributesContext.Provider value={value} {...props} />;
}

export const useAttributes = () => {
  const context = useContext(AttributesContext);
  if (context === undefined) {
    throw new Error('useAttributes must be used within a SettingsProvider');
  }
  return context;
};
