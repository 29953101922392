/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import TreeMenuItem from './TreeMenuItem';

function TreeMenu(props) {
  const { className } = props;
  const { t } = useTranslation('common');
  const catalogPages = useSelector((state) => state.pagesReducer.catalogPages);

  if (isEmpty(catalogPages)) return;

  return (
    <ul className={cn('text-xs', className)}>
      <li className="py-1 rounded-md">
        <Link className="flex items-center w-full py-2 text-start outline-none text-body-dark font-semibold focus:outline-none focus:text-orange-400 transition-all ease-in-expo text-sm" to="/">
          {t('Все категории')}
        </Link>
      </li>
      {catalogPages[0]?.children.map((item, i) => (
        item.isVisible && (
          <TreeMenuItem
            key={`${item.id}_${i}`}
            item={item}
          />
        )
      ))}
    </ul>
  );
}

export default TreeMenu;
