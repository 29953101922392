import { post } from '../../api/api';

export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';

const fetchFileUploadStart = () => ({
  type: FILE_UPLOAD_START,
});

const fetchFileUploadSuccess = (data) => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: data,
});

const fetchFileUploadFail = (err) => ({
  type: FILE_UPLOAD_FAIL,
  payload: err,
});

export const fetchFileUpload = (formData, id) => async (dispatch) => {
  dispatch(fetchFileUploadStart());
  try {
    const url = `${process.env.REACT_APP_API_URL}file-upload?type=form&entity=images&id=${id}`;
    const { data } = await post(url, formData);
    dispatch(fetchFileUploadSuccess(data));
    return data;
  } catch (err) {
    dispatch(fetchFileUploadFail(err));
    return false;
  }
};
