import React from 'react';
import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

function Scrollbar({
  options, className, style, ...props
}) {
  return (
    <OverlayScrollbarsComponent
      options={{
        className: cn('os-theme-thin-dark', className),
        scrollbars: {
          autoHide: 'scroll',
        },
        ...(options || {}),
      }}
      style={style}
      {...props}
    />
  );
}

export default Scrollbar;
