/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import {
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS
} from './action';

const initialState = {
  templates: {},
  total: null,
  isLoading: false,
};
let newData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_TEMPLATES_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_TEMPLATES_SUCCESS:
    // newData = state.templates;
    // newData.splice(payload.id, 0, payload);
    // newData.sort((a, b) => a.id - b.id);
    // newData[payload.id] = payload;
    return {
      ...state,
      templates: payload,
      isLoading: false,
    };
  case FETCH_TEMPLATES_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  default:
    return { ...state };
  }
};
