import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import logo from '../../assets/logo.svg';

function Logo({ className }) {
  return (
    <Link
      to="/"
      className={cn('inline-flex', className)}
    >
      <span className="overflow-hidden relative w-32 md:w-40">
        <img
          src={logo}
          alt="Logo"
        />
      </span>
    </Link>
  );
}

export default Logo;
