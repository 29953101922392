import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchDrawerType } from '../../redux/drawer/action';

import NavbarIcon from '../icons/NavbarIcon';
import HomeIcon from '../icons/HomeIcon';
import useIsRTL from '../../hooks/useIsRTL';

function MobileNavigation() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRTL } = useIsRTL();

  function handleSidebar() {
    dispatch(fetchDrawerType({ display: true, view: 'MAIN_MENU_VIEW' }));
  }

  return (
    <div className="visible lg:hidden">
      <nav className="h-12 md:h-14 w-full py-1.5 px-2 flex justify-between fixed left-0 bottom-0 z-10 bg-light shadow-400">
        <motion.button
          whileTap={{ scale: 0.88 }}
          onClick={() => handleSidebar()}
          className="flex p-2 h-full items-center justify-center focus:outline-none focus:text-orange-400"
        >
          <span className="sr-only">{t('text-burger-menu')}</span>
          <NavbarIcon className={`${isRTL && 'transform rotate-180'}`} />
        </motion.button>

        <motion.button
          whileTap={{ scale: 0.88 }}
          onClick={() => navigate('/')}
          className="flex p-2 h-full items-center justify-center focus:outline-none focus:text-orange-400"
        >
          <span className="sr-only">{t('text-home')}</span>
          <HomeIcon />
        </motion.button>
      </nav>
    </div>
  );
}

export default MobileNavigation;
