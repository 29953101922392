import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { useProductsQuery } from '../../redux/products/action';
import { useSearch } from '../../redux/products/searchContext';

import ErrorMessage from '../ui/ErrorMessage';

import GridWithLoader from './grids/GridWithLoader';
import ProductCard from './ProductCard';

function Products(props) {
  const {
    limit = 20,
    id = null,
  } = props;

  const [searchParams] = useSearchParams();

  let mode = 'all';
  const category = searchParams.get('category');
  const catalogPages = useSelector((state) => state.pagesReducer.pages);
  const currentPage = catalogPages.find((p) => p.pageUrl === category);
  const pageId = currentPage?.id;

  const { searchTerm } = useSearch();
  if (!isEmpty(searchTerm)) {
    mode = 'search';
  }

  if (!isEmpty(category)) {
    mode = 'filter';
  }

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage
  } = useProductsQuery({
    limit,
    mode,
    searchTerm,
    pageId,
    id
  });

  if (error || isLoading || isFetchingNextPage) {
    return null;
  }

  if (isError && error) {
    return (
      <ErrorMessage message={error.message} />
    );
  }

  const { pages } = data;

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <GridWithLoader
      notFound={!isFetching && !pages.length}
      showLoaders={isFetching && isLoading}
      hasNextPage={hasNextPage}
      isLoadingMore={isFetching}
      onLoadMore={handleLoadMore}
      limit={limit}
    >
      {
        pages?.map((page) => (
          page?.map((product, j) => (
            <ProductCard
              key={j}
              product={product}
            />
          ))
        ))
      }
    </GridWithLoader>
  );
}

export default Products;
