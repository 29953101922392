/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useInfiniteQuery } from 'react-query';
import API_ENDPOINTS from '../../api/api-endpoints';
import { get } from '../../api/api';

export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL';

const fetchTemplatesStart = () => ({
  type: FETCH_TEMPLATES_START,
});

export const fetchTemplatesSuccess = (data) => ({
  type: FETCH_TEMPLATES_SUCCESS,
  payload: data,
});

const fetchTemplatesFail = (err) => ({
  type: FETCH_TEMPLATES_FAIL,
  payload: err,
});

export const getTemplates = (props) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const link = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.TEMPLATES}all`;
    const result = await get(link);
    dispatch(fetchTemplatesSuccess(result.data));
  } catch (err) {
    dispatch(fetchTemplatesFail(err));
  }
};

export const getTemplatesByType = (props) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  // forUsualPage
  const { type } = props;
  try {
    const link = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.TEMPLATES}?type=${type}`;
    const result = await get(link);
    dispatch(fetchTemplatesSuccess(result.data));
  } catch (err) {
    dispatch(fetchTemplatesFail(err));
  }
};

export const getTemplateById = (props) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  const { id } = props;
  if (!id) return;
  try {
    const link = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.TEMPLATES}${id}`;
    const result = await get(link);
    dispatch(fetchTemplatesSuccess(result.data));
  } catch (err) {
    dispatch(fetchTemplatesFail(err));
  }
};
