import { combineReducers } from 'redux';

import attributesSetsReducer from './attributes/reducer';
import drawerReducer from './drawer/reducer';
import fileUploadReducer from './file-upload/reducer';
import formsReducer from './forms/reducer';
import menusReducer from './menus/reducer';
import pageReducer from './page/reducer';
import pagesReducer from './pages/reducer';
import productsReducer from './products/reducer';
import templatesReducer from './templates/reducer';

const Reducer = combineReducers({
  attributesSetsReducer,
  drawerReducer,
  fileUploadReducer,
  formsReducer,
  menusReducer,
  pagesReducer,
  pageReducer,
  productsReducer,
  templatesReducer,
});

export default Reducer;
