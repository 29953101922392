const API_ENDPOINTS = {
  // get
  FORMS: 'forms/',
  PAGES: 'pages',
  BLOCKS: 'blocks/',
  ATTRIBUTES_SETS: 'attributes-sets/',
  MARKERS: 'markers/',
  TEMPLATES: 'templates/',
  GENERAL_TYPES: 'general-types/',
  PRODUCTS: 'products',
  // PRODUCTS: 'products/indexed',
  PRODUCTS_BY_PAGE_ID: 'products/page/',
  MENUS: 'menus/',
  // send data
  FORM_DATA: 'form-data/',
  IMAGES: 'file-upload/',
  FILES: 'file-upload/',
};

export default API_ENDPOINTS;
