import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'react-html-parser';

// import { getPageById } from '../../redux/pages/action';
import { postFormData } from '../../redux/forms/action';
import { fetchFileUpload } from '../../redux/file-upload/action';

import ContactForm from '../../components/contact-form/ContactForm';

import { useSettings } from '../../settings/settings';
import usePage from '../../hooks/usePage';

import NotFound from '../../components/ui/not-found/NotFound';
import Spinner from '../../components/ui/spinner/Spinner';
// import ensure from '../../utils/ensure';

function ContactPage(props) {
  const { page } = props;

  const { t } = useTranslation('common');
  const { lang } = useSettings();

  const dispatch = useDispatch();
  const [inputState, setInputState] = useState({});
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState({ name: '', img: [] });

  const { p, isLoading } = usePage(page.id);

  if (isLoading) return <Spinner />;

  if (isEmpty(p)) return <NotFound />;

  const { forms, attributeValues } = p;
  const form = forms.find((f) => f.id === 2);

  // attributesSets
  const {
    address,
    follow,
    phone,
    illustration
  } = attributeValues;

  const image = illustration[0]?.downloadLink;
  const followLinks = follow[0].value;

  const handleSetFiles = (fls) => {
    setFiles(fls);
  };

  const handleChange = (e, title) => {
    const { name, value } = e.target;
    setInputState((prevState) => ({
      ...prevState,
      [name]: {
        value,
        title,
      },
    }));
  };

  const formReset = () => {
    setInputState({});
    setImages({ img: [], name: '' });
  };

  const onLoadImages = (name, img) => {
    setImages((prevState) => ({ ...prevState, name, img }));
  };

  const uploadImages = async () => {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of images.img) {
      formData.append('file', item);
    }
    try {
      const res = await dispatch(fetchFileUpload(formData, form.id));
      const imagesNames = res.map((img) => img.filename);
      return { [images.name]: { title: 'Изображение', value: imagesNames } };
    } catch (e) {
      console.log('Ошибка при загрузке изображений');
      return e;
    }
  };

  const onSubmit = async (values) => {
    const formData = [];

    if (images.img.length !== 0) {
      const uploadedImages = await uploadImages();
      if (uploadedImages) {
        formData.push(uploadedImages);
      } else {
        return;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const pop in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(pop)) {
        formData.push({ [pop]: values[pop] });
      }
    }

    // eslint-disable-next-line no-unused-vars
    const data = {
      formID: form.id,
      formData,
    };

    dispatch(postFormData(data));
    formReset();
    setFiles([]);
  };

  return (
    <div className="w-full bg-gray-100">
      <div className="flex flex-col md:flex-row max-w-7xl w-full mx-auto py-10 px-5 xl:py-14 xl:px-8 2xl:px-14">
        {/* sidebar */}
        <div className="w-full md:w-72 lg:w-96 bg-light p-5 flex-shrink-0 order-2 md:order-1">
          <div className="w-full flex items-center justify-center overflow-hidden mb-8">
            <img
              src={image}
              alt={t('Contacts')}
              className="w-full h-auto"
            />
          </div>

          <div className="flex flex-col mb-8">
            <span className="font-semibold text-heading mb-3">
              {t('Address')}
            </span>
            <span className="text-sm text-body">
              {address || t('Please add address in page attributes')}
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <span className="font-semibold text-heading mb-3">
              {t('Phone')}
            </span>
            <span className="text-sm text-body">
              {phone || t('Please add phone in page attributes')}
            </span>
          </div>
          <div className="flex flex-col mb-8">
            <span className="font-semibold text-heading mb-4">
              {t('Follow us')}
            </span>
          </div>
          <div className="flex flex-col mb-8">
            {ReactHtmlParser(followLinks) || t('Please add content')}
          </div>
        </div>

        {/* Contact form */}
        <div className="w-full order-1 md:order-2 mb-8 md:mb-0 md:ms-7 lg:ms-9 p-5 md:p-8 bg-light">
          <h1 className="mb-7 text-xl md:text-2xl font-body font-bold text-heading">
            {form?.localizeInfos?.[lang]?.titleForSite}
          </h1>
          {form && (
            <ContactForm
              files={files}
              handleSetFiles={handleSetFiles}
              form={form}
              onLoadImages={onLoadImages}
              handleSubmit={onSubmit}
              inputState={inputState}
              handleChange={handleChange}
            />
          )}
        </div>
      </div>
      <a
        target="_blank"
        href={`https://demo.dev.oneentry.cloud/content/edit-page/${page.id}`}
        className="edit-page"
        rel="noreferrer"
      >
        Edit
      </a>
    </div>
  );
}

export default ContactPage;
