import React from 'react';
import usePrice from '../../../hooks/usePrice';
// import isEmpty from 'lodash/isEmpty';

export default function VariationPrice(props) {
  const {
    selectedVariation,
    minPrice,
    maxPrice,
  } = props;

  const { price, basePrice } = usePrice(
    selectedVariation && {
      amount: selectedVariation.sale_price
        ? selectedVariation.sale_price
        : selectedVariation.price,
      baseAmount: selectedVariation.price,
    }
  );
  // const { price: minPrice } = usePrice({
  //   amount: minPrice,
  // });
  // const { price: maxPrice } = usePrice({
  //   amount: maxPrice,
  // });
  return (
    <span className="flex items-center">
      <ins className="text-2xl md:text-3xl font-semibold text-orange-400 no-underline">
        {selectedVariation ? `${price}` : `${minPrice} - ${maxPrice}`}
      </ins>
      {basePrice && (
        <del className="text-sm md:text-base font-normal text-muted ms-2">
          {basePrice}
        </del>
      )}
    </span>
  );
}
