/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React from 'react';
import cn from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSettings } from '../../../settings/settings';

function StaticMenu(props) {
  // const { } = props;
  const { lang } = useSettings();
  const location = useLocation();

  const menus = useSelector((state) => state.menusReducer.menus);
  const pages = menus.find((menu) => menu.id === 1)?.pages;

  return (
    pages?.map((page) => {
      const { pageUrl, localizeInfos } = page;
      const { title, content } = localizeInfos[lang];
      return (
        <li key={`${pageUrl}${title}`}>
          <Link
            title={content}
            to={`/${pageUrl}`}
            className={cn(
              'font-semibold text-heading flex items-center transition duration-200 no-underline hover:text-orange-400 focus:text-orange-400',
              location.pathname.includes(pageUrl) && 'text-orange-400'
            )}
          >
            {title}
          </Link>
        </li>
      );
    })
  );
}

export default StaticMenu;
