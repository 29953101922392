/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Popup from './details/Popup';

function ProductPopup({ productSlug }) {
  const { product, isLoading } = productSlug;
  return <Popup product={product} loading={isLoading} />;
}

export default ProductPopup;
