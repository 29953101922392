/* eslint-disable no-unused-vars */
import API_ENDPOINTS from '../../api/api-endpoints';
import { get } from '../../api/api';

export const FETCH_PAGE_START = 'FETCH_PAGE_START';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAIL = 'FETCH_PAGE_FAIL';

// fetch Pages
const fetchPageStart = () => ({
  type: FETCH_PAGE_START,
});

const fetchPageSuccess = (data) => ({
  type: FETCH_PAGE_SUCCESS,
  payload: data,
});

const fetchPageFail = (err) => ({
  type: FETCH_PAGE_FAIL,
  payload: err,
});

export const getPageById = (id) => async (dispatch) => {
  dispatch(fetchPageStart());
  try {
    const url = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PAGES}/${id}`;
    const { data } = await get(url);
    dispatch(fetchPageSuccess(data));
  } catch (err) {
    dispatch(fetchPageFail(err));
  }
};
