import { useMemo } from 'react';
import { useSettings } from '../settings/settings';

export function formatPrice(props) {
  const {
    amount,
    currencyCode,
    locale,
  } = props;

  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  return formatCurrency.format(amount);
}

export function formatVariantPrice(props) {
  const {
    amount,
    baseAmount,
    currencyCode,
    locale,
  } = props;

  const hasDiscount = baseAmount < amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const discount = hasDiscount ? formatDiscount.format((amount - baseAmount) / amount) : null;

  const price = formatPrice({ amount, currencyCode, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null;

  return { price, basePrice, discount };
}

function usePrice(data) {
  const { currency } = useSettings();
  const { amount, baseAmount, currencyCode = currency } = data || {};
  const { lang } = useSettings();

  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) { return ''; }

    return baseAmount ? formatVariantPrice({
      amount, baseAmount, currencyCode, lang
    }) : formatPrice({ amount, currencyCode, lang });
  }, [amount, baseAmount, currencyCode, lang]);

  return typeof value === 'string' ? { price: value, basePrice: null, discount: null } : value;
}

export default usePrice;
