import React, { useRef, useState } from 'react';
import { productPlaceholder } from './placeholders';
import {
  Swiper,
  SwiperSlide,
  // SwiperOptions,
  Navigation,
  Thumbs,
} from './slider/Slider';
// import { Image } from '@components/ui/image';

import useIsRTL from '../../hooks/useIsRTL';
import { ChevronLeft, ChevronRight } from '../icons';
// product gallery breakpoints
const galleryCarouselBreakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 3,
  },
  800: {
    slidesPerView: 4,
  },
};

const swiperParams = {
  slidesPerView: 1,
  spaceBetween: 0,
};

function ThumbsCarousel({ gallery }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { isRTL } = useIsRTL();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <div>
      <div className="relative">
        <Swiper
          id="productGallery"
          modules={[Navigation, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          {...swiperParams}
        >
          {gallery?.map((item) => (
            <SwiperSlide
              key={`product-gallery-${item.id}`}
              className="flex items-center justify-center selection:bg-transparent"
            >
              <img
                src={item?.original ?? productPlaceholder}
                alt={`Product gallery ${item.id}`}
                width={450}
                height={450}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          ref={prevRef}
          className="product-gallery-prev cursor-pointer absolute top-2/4 -start-4 md:-start-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-gray-100"
        >
          {isRTL ? (
            <ChevronRight className="w-4 h-4" />
          ) : (
            <ChevronLeft className="w-4 h-4" />
          )}
        </div>
        <div
          ref={nextRef}
          className="product-gallery-next cursor-pointer absolute top-2/4 -end-4 md:-end-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-gray-100"
        >
          {isRTL ? (
            <ChevronLeft className="w-4 h-4" />
          ) : (
            <ChevronRight className="w-4 h-4" />
          )}
        </div>
      </div>
      {/* End of product main slider */}

      <div className="max-w-md mt-5 lg:mt-8 mx-auto relative lg:pb-2">
        <Swiper
          id="productGalleryThumbs"
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          watchSlidesProgress
          freeMode
          observer
          observeParents
          breakpoints={galleryCarouselBreakpoints}
        >
          {gallery?.map((item) => (
            <SwiperSlide
              key={`product-thumb-gallery-${item.id}`}
              className="flex items-center justify-center cursor-pointer rounded overflow-hidden border border-border-200 border-opacity-75 hover:opacity-75"
            >
              <img
                src={item?.thumbnail ?? productPlaceholder}
                alt={`Product thumb gallery ${item.id}`}
                width={80}
                height={80}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ThumbsCarousel;
