/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useModalAction } from '../../ui/modal/modalContext';
import Button from '../../ui/button/Button';

export default function JoinButton(props) {
  const { t } = useTranslation('common');
  const { openModal } = useModalAction();

  const handleJoin = () => openModal('SHOP_INFO');

  return (
    <Button
      className="font-semibold"
      variant="outline"
      size="small"
      onClick={handleJoin}
    >
      {t('Join')}
    </Button>
  );
}
