import React from 'react';
import { useTranslation } from 'react-i18next';

import rangeMap from '../../../utils/range-map';
import Button from '../../ui/button/Button';
import NotFound from '../../ui/not-found/NotFound';
import ProductLoader from '../../ui/loaders/spinner';

function GridWithLoader(props) {
  const {
    notFound,
    showLoaders,
    hasNextPage,
    isLoadingMore,
    onLoadMore,
    limit,
    children,
  } = props;

  const { t } = useTranslation('common');

  if (notFound) {
    return (
      <div className="bg-gray-100 w-full min-h-full pt-6 pb-8 px-4 lg:p-8">
        <NotFound
          text="Not found"
          className="w-7/12 mx-auto"
        />
      </div>
    );
  }

  return (
    <div className="flex-1 bg-gray-100 pb-8 px-4 lg:px-0">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 3xl:grid-cols-5">
        {showLoaders ? (
          <>
            {rangeMap(limit, (i) => (<ProductLoader key={i} uniqueKey={`product-${i}`} />))}
          </>
        ) : (
          children
        )}
      </div>
      {hasNextPage && (
        <div className="flex justify-center mt-8 lg:mt-12">
          <Button
            loading={isLoadingMore}
            onClick={onLoadMore}
            className="text-sm md:text-base font-semibold h-11"
          >
            {t('Load more')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default GridWithLoader;
