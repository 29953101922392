import sortByPosition from './sortByPosition';

function makeTree(data, parentId, parent, positionProp) {
  const newData = [];
  [...sortByPosition(data, positionProp)]
    .filter((d) => d[parentId] === parent)
    .forEach((item) => {
      const newItem = item;
      newItem.children = makeTree(data, parentId, item.id);
      newData.push(newItem);
    });
  return newData;
}

export default makeTree;
