import React from 'react';
import cn from 'classnames';
import styles from './spinner.module.css';

function Spinner(props) {
  const {
    className, showText = true, text = 'Loading'
  } = props;

  return (
    <span
      className={cn('w-full flex flex-col items-center justify-center h-screen', className)}
    >
      <span className={styles.loading} />

      {showText && <h3 className="text-lg font-semibold text-body italic">{text}</h3>}
    </span>
  );
}

export default Spinner;
