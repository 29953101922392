import { get } from '../../api/api';
import API_ENDPOINTS from '../../api/api-endpoints';

export const FETCH_ATTRIBUTES_SETS_START = 'FETCH_ATTRIBUTES_SETS_START';
export const FETCH_ATTRIBUTES_SETS_SUCCESS = 'FETCH_ATTRIBUTES_SETS_SUCCESS';
export const FETCH_ATTRIBUTES_SETS_FAIL = 'FETCH_ATTRIBUTES_SETS_FAIL';

const fetchAttributesSetsStart = () => ({
  type: FETCH_ATTRIBUTES_SETS_START,
});

const fetchAttributesSetsSuccess = (data) => ({
  type: FETCH_ATTRIBUTES_SETS_SUCCESS,
  payload: data,
});

const fetchAttributesSetsFail = (err) => ({
  type: FETCH_ATTRIBUTES_SETS_FAIL,
  payload: err,
});

export const getAttributesSets = () => async (dispatch) => {
  dispatch(fetchAttributesSetsStart());
  try {
    const { data } = await get(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTRIBUTES_SETS}`);
    dispatch(fetchAttributesSetsSuccess(data));
  } catch (err) {
    dispatch(fetchAttributesSetsFail(err));
  }
};

export const getAttributesSetById = (id) => async (dispatch) => {
  dispatch(fetchAttributesSetsStart());
  try {
    const { data } = await get(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTRIBUTES_SETS}${id}`);
    dispatch(fetchAttributesSetsSuccess(data));
  } catch (err) {
    dispatch(fetchAttributesSetsFail(err));
  }
};
