/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../ui/button/Button';
import FormField from '../ui/forms/form-field/FormField';
// import ensure from '../../utils/ensure';
import getAttributeSetId from '../../utils/getAttributeSetId';
import { getAttributesSetById } from '../../redux/attributes/action';
import { useSettings } from '../../settings/settings';

function ContactForm(props) {
  const {
    handleSubmit,
    form,
    onLoadImages,
    inputState,
    handleChange,
    files,
    handleSetFiles,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { lang } = useSettings();

  const isLoading = useSelector((state) => state.formsReducer.isLoading);

  const { attributesSets } = form;

  useEffect(() => {
    const id = getAttributeSetId(attributesSets);
    dispatch(getAttributesSetById(id));
  }, [attributesSets, dispatch]);

  const { schema } = useSelector((state) => state.attributesSetsReducer.attributesSets);

  if (!schema) {
    return;
  }

  const formFields = Object.values(schema).map((item) => ({
    title: item.localizeInfos[lang].title,
    placeholder: attributesSets[`string_id${item.id}`],
    type: item.type === 'text' ? 8 : 0,
    identifier: item.identifier
  }));

  return (
    <form>
      <div>
        {formFields?.map((field) => (
          <FormField
            field={field}
            key={field.identifier}
            files={files}
            handleSetFiles={handleSetFiles}
            onLoadImages={onLoadImages}
            handleChange={handleChange}
            inputState={inputState}
            className="my-6"
            variant="outline"
          />
        ))}
        <Button
          onClick={() => handleSubmit(inputState)}
          type="button"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('Submit')}
        </Button>
      </div>
    </form>
  );
}

export default ContactForm;
